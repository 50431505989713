import { Link } from "react-router-dom";
import { Routes } from "../routes";
import { useAuth } from "../contexts/auth";
import { useQuery } from "@tanstack/react-query";
import { UserStatsData, UserStatsKey } from "./query";
import { api } from "../hooks";
import { useEffect, useRef, useState } from "react";
import { Tooltip } from "../components";
import { ProgrammationTypeEnumUtil } from "../utils";

function Home() {
  const { user } = useAuth();

  const [isMobile, setIsMobile] = useState(false);

  const { data: userStats } = useQuery<UserStatsData>({ queryKey: UserStatsKey, queryFn: api.home.stats, refetchOnWindowFocus: true });

  useEffect(() => {
    windowResized();
    window.addEventListener("resize", windowResized, false);

    return () => {
      window.removeEventListener("resize", windowResized, false);
    };
  }, []);

  const windowResized = () => {
    setIsMobile(window.innerWidth < 992);
  };

  return (
    <div className="row h-100" style={{ overflow: isMobile ? "" : "hidden" }}>
      <div className="col-12 col-lg-8" style={{ height: isMobile ? "" : "100%" }}>
        <div className="d-flex justify-content-center" style={{ containerType: "inline-size", height: isMobile ? "100px" : "20%" }}>
          <h1 className="align-self-center fw-bold text-center mb-0" style={{ fontSize: isMobile ? "5cqw" : "5.5cqmin", whiteSpace: "nowrap", fontFamily: "Bevan" }}>
            Les applications M@ths en-vie
          </h1>
        </div>
        <div className="row" style={{ height: isMobile ? "" : "66%" }}>
          <div className="col-12 col-lg-6 d-lg-flex flex-column justify-content-evenly" style={{ height: isMobile ? "" : "100%", marginBottom: isMobile ? "40px" : "" }}>
            <div className="text-center" style={{ height: isMobile ? "200px" : "50%", marginBottom: isMobile ? "40px" : "" }}>
              <a href="https://www.mathsenvie.fr" target="_blank" rel="noreferrer">
                <img src="img/logo.webp" className="mx-100 mw-100 h-100" style={{ objectFit: "contain" }} alt="Site M@ths en-vie" />
              </a>
            </div>
            <div className="text-center">
              {user ? (
                <div className="rounded-5 bg-success mx-auto p-2 px-5 text-white" style={{ width: "fit-content", fontFamily: "Bevan", fontSize: "1.5rem" }}>
                  Connecté
                </div>
              ) : (
                <Link className="btn btn-ternary btn-lg rounded-5 p-2 px-5" style={{ fontFamily: "Bevan" }} to={Routes.LOGIN}>
                  Se connecter
                </Link>
              )}
              <div className="mt-3" style={{ fontSize: "19px" }}>
                <span className="fw-bold">{userStats?.userRegisteredAmout}</span> utilisateurs enregistrés
              </div>
            </div>
          </div>
          {isMobile && <AppsCards />}
          <div className="col-12 col-lg-6 h-100">
            <div className="h-100 mx-auto d-flex justify-content-center flex-column" style={{ width: "min(90%, 440px)", margin: isMobile ? "50px 0" : "" }}>
              <StatistiqueBadge name="Mes contributions" value={userStats?.contributions.toString()} />
              <StatistiqueBadge name="Problèmes résolus" value={userStats?.problemsResolvedAmouts.toString()} />
              <StatistiqueBadge name="Ma programmation" value={user ? ProgrammationTypeEnumUtil.toString(user.programmationType) : undefined} />
              <StatistiqueBadge name="Ma zone" value={userStats?.zone} />
              <StatistiqueBadge name="Niveau(x) de classe" value={user ? (user?.classNivel.length > 0 ? user.classNivel.map((v) => v.toUpperCase()).join(" - ") : "Non défini") : undefined} />
              <StatistiqueBadge name="Code adhérent" value={user ? (user.isAdherent ? "OUI" : "NON") : undefined} />
            </div>
          </div>
        </div>
        <div className="p-3 w-100 d-flex justify-content-center column-gap-3 row-gap-5" style={{ height: isMobile ? "" : "14%", flexWrap: isMobile ? "wrap" : "nowrap" }}>
          <div className="align-self-center text-center h-100" style={{ flex: isMobile ? "1 0 50px" : "" }}>
            <a href="https://www.mathsenvie.fr/association" target="_blank" rel="noreferrer">
              <img src="img/logoAsso.webp" alt="Association Logo" className="mw-100 mh-100 h-100" style={{ objectFit: "contain" }} />
            </a>
          </div>
          <div className="align-self-center text-center h-100" style={{ flex: isMobile ? "1 0 100px" : "" }}>
            <a href="https://dane.web.ac-grenoble.fr/" target="_blank" rel="noreferrer">
              <img className="mw-100 mh-100 h-100" style={{ objectFit: "contain" }} src="img/logoDane.webp" alt="Logo dane Grenoble" />
            </a>
          </div>
          <div className="align-self-center text-center h-100" style={{ flex: isMobile ? "1 0 120px" : "" }}>
            <a href="https://www.generation5.fr/views/pages/mathsenvie.php" rel="noreferrer" target="_blank">
              <img className="mw-100 mh-100 h-100" style={{ objectFit: "contain" }} src="img/logoGeneration5.webp" alt="Logo génération 5" />
            </a>
          </div>
          <div className="align-self-center text-center h-100" style={{ flex: isMobile ? "1 0 100px" : "" }}>
            <a href="https://docs.forge.apps.education.fr/" rel="noreferrer" target="_blank">
              <img className="mw-100 mh-100 h-100" style={{ objectFit: "contain" }} src="img/logoForge.webp" alt="Logo la forge numérique" />
            </a>
          </div>
          <div className="align-self-center text-center h-100" style={{ flex: isMobile ? "1 0 100px" : "" }}>
            <a href="https://fr.school.beneylu.com/" rel="noreferrer" target="_blank">
              <img className="mw-100 mh-100 h-100" style={{ objectFit: "contain" }} src="img/logoBeneylu.webp" alt="Logo Beneylu School" />
            </a>
          </div>
          <div className="align-self-center text-center h-100" style={{ flex: isMobile ? "1 0 100px" : "" }}>
            <a href="https://tandem.nathan.fr/" rel="noreferrer" target="_blank">
              <img className="mw-100 mh-100 h-100" style={{ objectFit: "contain" }} src="img/logoNathan.webp" alt="Logo Nathan" />
            </a>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="row col-4 h-100">
          <AppsCards />
        </div>
      )}
    </div>
  );
}

function AppsCards() {
  return (
    <>
      <div className="text-center w-100" style={{ height: "33.3333%" }}>
        <a href={process.env.REACT_APP_ATELIER_URL} style={{ perspective: "200px" }}>
          <img className="mw-100 mh-100 h-100 p-3 card-application" style={{ objectFit: "contain" }} src="img/app_atelier.webp" alt="L'atelier des problèmes" />
        </a>
      </div>
      <div className="text-center w-100" style={{ height: "33.3333%" }}>
        <Link to={Routes.RITUEL} style={{ perspective: "200px" }}>
          <img className="mw-100 mh-100 h-100 p-3 card-application" style={{ objectFit: "contain", animationDelay: "0.5s" }} src="img/app_rituel.webp" alt="Le rituel de problèmes" />
        </Link>
      </div>
      <div className="text-center w-100" style={{ height: "33.3333%" }}>
        <a href={process.env.REACT_APP_BANQUE_URL} style={{ perspective: "200px" }}>
          <img className="mw-100 mh-100 h-100 p-3 card-application" style={{ objectFit: "contain", animationDelay: "1s" }} src="img/app_banque.webp" alt="La banque de problèmes" />
        </a>
      </div>
    </>
  );
}

type StatistiqueBadgeProps = {
  name: string;
  value: string | undefined;
};

function StatistiqueBadge({ name, value }: Readonly<StatistiqueBadgeProps>) {
  const hasEllipsis = (elementItem: any) => {
    if (!elementItem) {
      return false;
    }
    let width = elementItem.offsetWidth;
    let widthParent = elementItem.parentElement.scrollWidth;
    return width >= widthParent;
  };

  const textValueRef = useRef<HTMLSpanElement>(null);

  return (
    <div className="py-1" style={{ height: "min(calc(100%/6), 50px)", minWidth: 100 }}>
      <div className="bg-secondary rounded-5 d-flex justify-content-between h-100" style={{ containerType: "inline-size" }}>
        <span className="align-self-center text-center fw-bold px-3 overflow-hidden" style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "60%", fontSize: "min(4.3cqmin, 19px)" }}>
          {name}
        </span>
        <div className="d-flex bg-primary rounded-5" style={{ containerType: "inline-size", width: "40%" }}>
          <Tooltip id="userStats" value={value ?? "..."} disabled={!hasEllipsis(textValueRef.current)}>
            <span
              ref={textValueRef}
              className="align-self-center text-white fw-bold text-center overflow-hidden px-3 mx-auto"
              style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "min(10cqmin, 19px)" }}
            >
              {value ?? "..."}
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default Home;
