import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangePasswordForm, GrantedAdherentForm, GrantedVipForm, ProgrammationTypeForm, RituelParametersForm } from "../../components";
import { faCalendarDays, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { ModalTypeEnum } from "../../utils";
import { useModals } from "../../contexts/modals";

function ProfilParameters() {
  const { openModal } = useModals();

  return (
    <div>
      <RituelParametersForm />
      <hr className="border-3" />
      <div className="text-center">
        <button className="btn btn-primary mb-3" onClick={() => openModal(ModalTypeEnum.Programmation)}>
          <FontAwesomeIcon icon={faCalendarDays} /> Programmation
        </button>
        <ProgrammationTypeForm />
      </div>
      <hr className="border-3" />
      <ChangePasswordForm />
      <hr className="border-3" />
      <GrantedAdherentForm />
      <GrantedVipForm />
      <hr className="border-3" />
      <button className="btn btn-danger" onClick={() => openModal(ModalTypeEnum.DeleteAccount)}>
        <FontAwesomeIcon icon={faTrashCan} /> Supprimer mon compte
      </button>
    </div>
  );
}

export default ProfilParameters;
